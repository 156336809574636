<template>
  <CommonPages id="home" title="Abacus AI" classs="home ">
    <div class="top-banner">
      <div class="banner-title">
        Open AI in your hands
      </div>
      <div class="banner-sub-title">
        SOTA language models for devs, unrestricted
      </div>
      <div class="banner-btn-row">
        <a class="banner-btn-item active" href="https://platform.abacistopia.com/demo/">
          Start Developing
        </a>
        <a class="banner-btn-item " href="https://platform.abacistopia.com/docs/">
          Read Docs
        </a>
      </div>
    </div>
    <div class="building-intro-container pc_padding">
      <div class="building-title">
        Build with <span>Abacus</span> API
      </div>
      <div class="coding-row">
        <div class="coding-inner">
          <pre>curl --location --request POST 'https://api.abacistopia.com/v1/modelChat/chat' \ 
--header 'Authorization: Bearer XXX' \ 
--header 'Content-Type: application/json; charset=utf-8' \ 
--data-raw '{"chargingModelId": 12, "context": [{"role": "user", "content": "Hello"}]}'</pre>
        </div>
      </div>

      <div class="building-title">
        Why <span>Abacus</span> AI?
      </div>
      <div class="build-ai-intro-row">
        <div v-for="(data,i) in introSource" :key="`source_${i}`" class="build-ai-intro-item">
          <img :src="data.img" alt="" class="build-ai-intro-img">
          <div class="build-ai-intro-title" v-html="data.title" />
          <div class="build-ai-intro-content" v-html="data.content" />
        </div>
      </div>

      <div class="building-title">
        Products
      </div>
      <div class="product-row">
        <div class="product-item">
          <div class="product-title">
            Large Language Models
          </div>
          <div class="product-icon product" />
          <div class="product-item-content">
            <div v-for="model in models" :key="model.id" class="model-item">
              <div class="model-name">
                {{ model.name }}
              </div>
              <div class="model-description">
                {{ model.description }}
              </div>
            </div>
          </div>
        </div>
        <div class="product-item">
          <div class="product-title">
            Chatbot Service
          </div>
          <div class="product-icon service" />
          <div class="product-item-content">
            <div v-for="service in services" :key="service.id" class="model-item">
              <div class="model-name">
                {{ service.name }}
              </div>
              <div class="model-description">
                {{ service.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import CommonPages from '@/pages/components/pc_common.vue'
import '@/css/main.scss'
import '@/css/common.scss';

export default {
  name: 'Home',
  components: {
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      models: [
        { id: 1, name: 'Abacus-pro', description: 'Flagship model' },
        { id: 2, name: 'Abacus', description: 'Best combination of performance and speed' },
        { id: 3, name: 'Abacus-flash', description: 'Lightweight, fast' }
      ],
      services: [
        { id: 1, name: 'Abacus-char', description: 'Role-play chat model' },
      ],
      introSource: [
        { img: require('@/assets/icon-1.png'), title: 'Openness & Freedom', content: 'Our unrestricted platform offers full flexibility, free from proprietary limitations.' },
        { img: require('@/assets/icon-2.png'), title: 'Performance & Speed', content: 'Our models deliver state-of-the-art performance and speed, ensuring efficient, seamless experiences.' },
        { img: require('@/assets/icon-3.png'), title: 'Flexible Model Selection', content: 'Varying model sizes let users choose the right fit for any use case, from complex to lightweight tasks.' },
        { img: require('@/assets/icon-4.png'), title: 'Cost-Effectiveness', content: '    Affordable AI capabilities without compromising on power, making advanced tech accessible to all.' },
      ],

      ////////
    };
  },
  metaInfo () {
    return {
      title: 'Abacus AI',
      meta: [
        {
          name: 'description',
          content: 'Abacus AI',
        },
      ],
    };
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },

};
</script>
