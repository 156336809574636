<template>
  <footer>
    <div class="foot-nav-container pc_padding">
      <div class="footer-center">
        <div class="footer-item-title">
          Contact us
        </div>
        <div class="footer-item-content">
          <a class="footer-item-content-item"
            href="mailto:sales@abacistopia.com">sales@abacistopia.com</a>
        </div>
      </div>
    </div>
    <div class="corner-module pc_padding">
      Copyright © 2024 Abacistopia Co., Limited. All Rights Reserved.
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FootModule1',
  computed: {
    ...mapGetters({
      host: 'host',
      deviceType: 'deviceType',
    }),
  },
  methods: {
    goto (where) {
      console.log(this.$route.path);
      this.$router.push({ name: where });
    },
  },
};
</script>
